import React from "react";
import Typography from '@mui/material/Typography';
import {CardActionArea, Card, CardContent, CardMedia} from '@mui/material';
import { JColors } from "../JStyles";
import '../App.css';

export function OldProjectCard(props) {
    return (
      <Card sx={{ maxWidth: 400, height: 400, userSelect:'none', marginBottom:'20px', marginRight:'5px', marginLeft:'5px', pointerEvents:'none' , backgroundColor: JColors.accentColor, borderRadius:'12px'}}>
        <CardActionArea>
          <CardMedia
            component="img"
            height="170"
            image={props.item.image}
            alt={props.item.label}
          />
          <CardContent>
            <h1 className="CardText" gutterBottom style={{fontFamily:'Space Grotesk', fontWeight:'bold', marginBottom:'20px', color:'white'}}>
              {props.item.label}
            </h1>
            <h1 className="CardText" style={{fontFamily:'Space Grotesk', fontWeight:'lighter', color:'white' }}>
              {props.item.description}
            </h1>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  }