import * as React from 'react';
import { useState } from 'react';
import { JColors } from "../JStyles";
import '../App.css';

export function IconButton(props) {

    const [hovered, setHovered] = useState(false);

    const handleChange = () => {
        setHovered(!hovered);
    }

    return (
        
        <a className={props.outerClass} onMouseEnter={handleChange} onMouseLeave={handleChange} href={props.url} style={{backgroundColor: props.color, transform: (hovered ? 'scale(1.1)' : 'scale(1)')}}>
            <img className={props.innerClass} src={props.icon} alt="" title={props.title}/>
        </a>

    )
}