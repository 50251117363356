import Clock from './images/clock.png';
import CableTester from './images/cableTester.png';
import JavaGame from './images/javagame.png';
import CppGame from './images/cppgame.png';
import NewGame from './images/newgame.png';
import OldWebsite from './images/oldwebsite.png';
import Blackbook from './images/blackbook.png';
import Cardinal from './images/cardinaldesign.png';

export const items = [
    {
      label: 'Arduino Study Clock (Python)',
      image: Clock,
      description: 'Pomodoro Technique Clock, using the Arduino which plays set songs once completing work or break sessions.',
    },
    {
      label: 'Cable Tester (Work Term)',
      image: CableTester,
      description: 'Cable Tester for Kraken Robotics, which would test end-to-end communications quickly during production.',
    },
    {
      label: 'Roguelike Dungeon-Crawler (Java)',
      image: JavaGame,
      description: 'Dungeon-crawler built in Java using both the swing and awt libraries. This project was made to showcase software design skills, using patterns like Model View Controller (MVC).',
    },
    {
      label: 'Roguelike Dungeon-Crawler (C++)',
      image: CppGame,
      description: `Similar to the previous, but instead built in C++ using the SFML library and focuses on the Entity Component System design pattern (ECS). The scope was also increased to have a weapon/spell system, level-editor, and more!`,
    },
    {
      label: 'Indie Townbuilder Game (Godot Engine)',
      image: NewGame,
      description: "Currently in-development, this game features an in-depth animation framework programmed in Godot's language, GDScript. This builds upon previous software techniques I have learned.",
    },
    {
      label: 'Original Portfolio Website (ReactJS)',
      image: OldWebsite,
      description: 'Previously on this domain, my old website was to self-teach myself ReactJS. It helped me gain a better understanding of web design and create a better website for both computer and mobile.',
    },
    {
      label: 'Cardinal (React Native + AWS + Swift)',
      image: Cardinal,
      description: `Our team of engineering students created a cardiovascular health app that gamifies healthy living. Using health data from Apple-Certified wearables, our machine-learning algorithms generate a health score and offer actionable insights to improve user health.`
    },
    {
      label: 'Blackbook (ReactJS + AWS)',
      image: Blackbook,
      description: `My latest indie project uses AWS Cognito, Lambda, S3, and API Gateway to create a cloud-based repository for online textbooks. This allows users to access textbooks for reading and supports both inline annotations directly on the text and separate note-taking on a different page.`
    }
];
