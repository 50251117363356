import { SectionBox } from "./SectionBox";
import TypeIt from 'typeit-react';
import { JColors } from "../JStyles";
import facetransparent from '../images/facetransparent.png';

export function Home() {

    return(

        <div className="MarginedDiv" style={{width:'auto', height:'auto'}}>
            <div className="PhotoDiv" style={{position:'relative', display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                <div className="SlideText" style={{}}>
                    
                    <h1 className='Text GreetingText'>Hi! I'm Josh.</h1>

                    <div className="FadeInText" style={{display:'flex', flexDirection:'row', clear:'both'}}>
                        <TypeIt className="TypeIt ImA" options={{ strings: ["I'm a"], speed: -100, cursor: false, waitUntilVisible: true, lifeLike: false}}/>
                        <TypeIt className="TypeIt RoleTitle" options={{ strings: ["Computer Engineer.", "Full-Stack Developer.", "Game Designer."], speed: 120, deleteSpeed: 60, waitUntilVisible: true, breakLines:false, lifeLike: false, nextStringDelay: 2500, loop:true}}/>
                    </div>

                    <h1 className="Text Quote">"People don't care about what you say, they care about what you build" - Mark Zuckerberg</h1>
                </div>

                <img className="PortraitCircle" src={facetransparent} style={{backgroundColor:JColors.accentColor, border: '1px solid white', borderColor:'white', overflow:'hidden', objectFit:'contain'}}/>
            </div>
        </div>
    );
}