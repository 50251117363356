import { JColors } from "../JStyles";
import { SectionBox } from "./SectionBox";
import { Download } from '@mui/icons-material';
import PDF from '../jnmosher_resume.pdf';
import ResumePNG from '../images/resume.png';

import '../App.css';

export function Resume() {

    return(
        <div className="MarginedDiv" style={{marginTop: '100px', display:'flex', flexDirection:'column', alignItems:'center'}}>
          <img className="Resume" src={ResumePNG} style={{borderColor:JColors.accentColor}}/>

          <div style={{ display:'flex', flexDirection:'row', alignItems:'center',justifyContent:'center', marginTop: '20px', height: '40px', width:'150px', backgroundColor: JColors.accentColor, borderRadius:'8px'}}>
            <Download style={{color:'white', fontSize:'20px'}}/>
            <a href={PDF} download="jnmosher_resume.pdf" style={{fontFamily:'Space Grotesk', fontSize:'20px', position:'relative', color:'white', textDecoration:'none' }}> Download </a>
          </div>
        </div>
    );
}