import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import { Element, scroller } from 'react-scroll';

import './App.css';
import Navbar from './Navbar';
import { OldSeparationBar } from './components/OldSeparationBar';
import { Home } from './components/Home';
import { OldAboutMe } from './components/OldAboutMe';
import { OldProjects } from './components/OldProjects';
import { OldWorkExp } from './components/OldWorkExp';
import { OldResume } from './components/OldResume';
import { StackBar } from './components/StackBar';
import { Projects } from './components/Projects';
import { CompanyBar } from './components/CompanyBar';
import { Resume } from './components/Resume';
import { Footer } from './components/Footer';

function TabPanel(props) 
{
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = 
{
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) 
{
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function App() {

  const scrollOptions = {
    duration: 500,
    smooth: true,
    offset: -75,
  };

  const [value, setValue] = React.useState(0);
  const handleChange = (newValue) => {
    scroller.scrollTo(newValue.toString(), scrollOptions)
  };

  return (
    <div className="App">

      <Stack direction='column'>
      
      <Navbar handleChange={handleChange}/>

      <Element name="0"/>
      <Home/>

      <StackBar/>

      <Element name="1"/>
      <OldProjects/>
      
      <CompanyBar/>
      
      <Element name="2"/>
      <Resume/>

      <Footer/>

      </Stack>
    </div>
  );
}

export default App;
