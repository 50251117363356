import { Component } from "react";
import "./NavBarStyles.css";
import logo from './images/jm2logo.png';
import { JColors } from "./JStyles";

class Navbar extends Component {
    state = { clicked: false };

    handleClick = () => {
        this.setState({clicked: !this.state.clicked})
    }

    handleSelect = (number) => {
        this.props.handleChange(number);
        this.handleClick();
    }

    render() {
        return(
            <>
            <div className="Sticky">
              <nav style={{backgroundColor: JColors.accentColor}}>
                  <a href="index.html" className='MarginedLeft' style={{userSelect:'none', pointerEvents:'none'}}>
                  <img src={logo} alt="" style={{ width: '90px'}}/>
                 </a>
      
                 <div className='MarginedRight'>
                  <ul id="navbar" className={this.state.clicked ? "#navbar active" : "#navbar"} style={{backgroundColor: JColors.accentColor}}>
                      <li>
                          <button type="button" onClick={()=>{this.handleSelect(0)}}>Home</button>
                      </li>
                      
                      <li>
                        <button type="button" onClick={()=>{this.handleSelect(1)}}>Projects</button>
                      </li>
                      
                      <li>
                        <button type="button" onClick={()=>{this.handleSelect(2)}}>Resume</button>
                      </li>
                  </ul>
                 </div>
      
                 <div id="mobile" onClick={this.handleClick} className="MarginedRight">
                      <i id="bar" className={this.state.clicked ? 'fas fa-times' : 'fas fa-bars'}></i>
                 </div>
              </nav>
            </div>
            <div style={{height: '65px'}}/>
            </>  
        );
    }
}

export default Navbar;