import { JColors } from '../JStyles';
import { IconButton } from './IconButton';
import '../App.css';

import kraken from '../images/kraken.png';
import ccore from '../images/c-core.png';
import cadence from '../images/cadence.png';
import cardinal from '../images/cardinal.png';


export const CompanyBar = (props) => {
    return (
      <div style={{width:'100%', height: '75px', backgroundColor: JColors.accentColor, display:'flex', flexDirection:'row', justifyContent:'space-evenly'}}>
        <IconButton outerClass={'IconButton'} innerClass={"CompanyBar"} icon={kraken} title={"Kraken Robotics"}/>
        <IconButton outerClass={'IconButton'} innerClass={"CompanyBar"} icon={ccore} title={"C-CORE"}/>
        <IconButton outerClass={'IconButton'} innerClass={"CompanyBar"} icon={cadence} title={"Cadence Design Systems"}/>
        <IconButton outerClass={'IconButton'} innerClass={"CompanyBar"} icon={cardinal} title={"Cardinal"}/>
      </div>
    );
}