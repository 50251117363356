import { JColors } from '../JStyles';
import { IconButton } from './IconButton';
import '../App.css';

import C from '../images/C.png';
import Cpp from '../images/C++.png';
import Java from '../images/Java.png';
import HTMLCSSJS from '../images/HTML-CSS-JavaScript.png';
import TS from '../images/Typescript.png';
import ReactImg from '../images/React.png';
import AWS from '../images/AWS.png';


export const StackBar = (props) => {
    return (
      <div style={{width:'100%', height: '75px', backgroundColor: JColors.accentColor, display:'flex', flexDirection:'row', justifyContent:'space-evenly'}}>
        <IconButton outerClass={'IconButton'} innerClass={"StackBar"} icon={C} title={"C (Language)"}/>
        <IconButton outerClass={'IconButton'} innerClass={"StackBar"} icon={Cpp} title={"C++ (Language)"}/>
        <IconButton outerClass={'IconButton'} innerClass={"StackBar"} icon={Java} title={"Java (Language)"}/>
        <IconButton outerClass={'IconButton'} innerClass={"StackBar"} icon={HTMLCSSJS} title={"HTML/CSS/JavaScript (Languages)"}/>
        <IconButton outerClass={'IconButton'} innerClass={"StackBar"} icon={TS} title={"TypeScript (Language)"}/>
        <IconButton outerClass={'IconButton'} innerClass={"StackBar"} icon={ReactImg} title={"React (Framework)"}/>
        <IconButton outerClass={'IconButton'} innerClass={"StackBar"} icon={AWS} title={"Amazon Web Services (Cloud)"}/>    
      </div>
    );
}