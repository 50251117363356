export const JColors = {
    accentColor: '#202734',
    backgroundColor: '#182237',
    textColor: '#FFFFFF',
    newAccentColor: '#202734',
    lightBlue: '#1F6BFF',
}

export const fontSizes = {

}