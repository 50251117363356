import { JColors } from "../JStyles";
import { items } from "../ProjectItems";
import { OldProjectCard } from "./OldProjectCard";

import { SectionBox } from "./SectionBox";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"

export function OldProjects() {
    
    return(

        
        <div>
          <div style={{marginTop: '100px', marginBottom: '100px', marginLeft: 'auto', marginRight:'auto', overflow:'visible'}}>

            <Slider infinite autoplay dots centerMode speed={2000} autoplaySpeed={4000} slidesToShow={1} slidesToScroll={1} rows={1} arrows={false} variableWidth responsive={[{breakpoint: 500, settings: { vertical: false, variableWidth: false}}]} >
              { items.map( (item, i) => <OldProjectCard key={i} item={item}/>) }
            </Slider>
          </div>
        </div>
    );
}