import { JColors } from "../JStyles";
import logo from '../images/jm2logo.png';
import { IconButton } from "./IconButton";
import '../App.css';

import linkedin from '../images/linkedin.png';
import github from '../images/github.png';
import email from '../images/email.png';

import { Typography } from "@mui/material";

export function Footer() {

    return(

        <div style={{ display:'flex', flexDirection:'column', alignItems:'center', backgroundColor: JColors.accentColor, paddingTop: '50px', paddingBottom: '50px'}}>

            <img src={logo} alt="" style={{ width:'90px'}}/>

            <div style={{display:"flex", flexDirection:'row', justifyContent:'space-evenly', alignItems:'center', width:'240px', marginTop: '10px'}}>
                <IconButton icon={linkedin} url={"https://www.linkedin.com/in/joshua-mosher/"} title={"LinkedIn"} outerClass={'IconButton SocialsButton'} innerClass={'SocialsButtonImg'}/>
                <IconButton icon={github} url={"https://github.com/JoshMosher"} title={"GitHub"} outerClass={'IconButton SocialsButton'} innerClass={'SocialsButtonImg'}/>
                <IconButton icon={email} url={"mailto:jnmosher@mun.ca"} title={"Email"} outerClass={'IconButton SocialsButton'} innerClass={'SocialsButtonImg'}/>
            </div>
            
            <h5 style={{color:'white', fontWeight:'lighter', fontSize:`max(calc(3vw - 38px), 15px)`, lineHeight:'0px'}}> Handcrafted by me © Joshua Mosher </h5>
        </div>

    );
}